import {
    FormContainer,
} from "./styles";
import { RedirectImage } from "../../components/RedirectApp/RedirectImage";

export const RedirectAppContainer = () => {


    return (

        <FormContainer>
            <RedirectImage/>
        </FormContainer>

    );
};
