import {
    ImageRedirectApp
  } from "./styles";

  import { fetchRemoteConfig } from "../../remote_config/remote_config_call";
  import { useState, useEffect } from "react";
  import { defaultConfig } from "../../remote_config/remote_keys";

  export const RedirectImage = () => {

    const [redirectImage, setRedirectImage] = useState("");
  
    useEffect(() => {
      const fetchRedirectImage = async () => {
        try {
          const redirectImage = await fetchRemoteConfig(
            "site_redirect_image",
            `${defaultConfig.redirectImage}`,
            "string"
          );
          setRedirectImage(redirectImage);
        } catch (error) {
          return setRedirectImage(defaultConfig.redirectImage);
        }
      };

      fetchRedirectImage();

    }, []);
  
    return (
          <ImageRedirectApp src={redirectImage} />
    );
  };